import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Header from '../components/header-my-account'
import MyAccCard from '../components/myacccard';
import BookParkingIcon from '../assets/svg/my-acc-book-icon-lrg.svg';
import LeaseABayIcon from '../assets/svg/my-acc-lease-icon-lrg.svg';
import CreateChargeIcon from '../assets/svg/my-acc-charge-icon-lrg.svg';
import FlexiPassIcon from '../assets/svg/my-acc-flexi-icon-lrg.svg';

export default function CenteredGrid() {
	return (
		<>
			<Header />
			
			<Container 
				className="MyAccContainer"
				maxWidth={false}>
				<Grid container spacing={2}>
					<Grid item xs={3}>
						<MyAccCard 
							icon={BookParkingIcon}
							title="Book Parking"
							subtitle="Pre-Book and secure your space"
							link=""
						/>
					</Grid>
					
					<Grid item xs={3}>
						<MyAccCard 
							icon={LeaseABayIcon}
							title="Lease a bay"
							subtitle="Lease a bay and experience the best of VIP Parking"
							link=""
						/>
					</Grid>
					
					<Grid item xs={3}>
						<MyAccCard 
							icon={CreateChargeIcon}
							title="Create a Charge Acc."
							subtitle="Charge parking to your account &amp; avoid queuing to pay"
							link=""
						/>
					</Grid>
						
					<Grid item xs={3}>
						<MyAccCard 
							icon={FlexiPassIcon}
							title="Get a Flexi Pass"
							subtitle="Purchase a multi-pass &amp; enjoy flexibility at a budget price"
							link=""
						/>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}