import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch, {SwitchProps} from '@mui/material/Switch';
import {styled} from '@mui/material/styles';
import Header from "../components/header-my-account";
import HeaderIcon from '../assets/svg/my-activity-heading-icon.svg';
import ProfileMenuIcon from '../assets/svg/my-acc-profile-icon-sml.svg';
import VehiclesMenuIcon from '../assets/svg/my-acc-vehicles-icon-sml.svg';
import CardsMenuIcon from '../assets/svg/my-acc-cards-icon-sml.svg';
import CarParksMenuIcon from '../assets/svg/my-acc-parks-icon-sml-2.svg';
import SubsMenuIcon from '../assets/svg/my-acc-subs-icon-sml.svg';
import SettingsMenuIcon from '../assets/svg/my-acc-settings-icon-sml.svg';
import SecurityMenuIcon from '../assets/svg/my-acc-security-icon-sml.svg';
import ForgetMeMenuIcon from '../assets/svg/my-acc-forget-icon-sml.svg';
import VisaLogo from '../assets/svg/ccard-icon-visa.svg';
import MCardLogo from '../assets/svg/ccard-icon-mcard.svg';
import EditIcon from '../assets/svg/edit-icon.svg';
import DeleteIcon from '../assets/svg/delete-icon.svg';
import AddItem from '../assets/svg/add-item-icon.svg';

const IOSSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 60,
  height: 28,
  padding: 0,
  '& .MuiSwitch-switchBase': {
	padding: 0,
	margin: 2,
	transitionDuration: '300ms',
	'&.Mui-checked': {
	  transform: 'translateX(32px)',
	  color: '#fff',
	  '& + .MuiSwitch-track': {
		backgroundColor: '#44C3A1',
		opacity: 1,
		border: 0,
	  },
	  '&.Mui-disabled + .MuiSwitch-track': {
		opacity: 0.5,
	  },
	},
	'&.Mui-focusVisible .MuiSwitch-thumb': {
	  color: '#33cf4d',
	  border: '6px solid #fff',
	},
	'&.Mui-disabled .MuiSwitch-thumb': {
	  color: 'black',
	},
	'&.Mui-disabled + .MuiSwitch-track': {
	  opacity: 0.5,
	},
  },
  '& .MuiSwitch-thumb': {
	boxSizing: 'border-box',
	width: 24,
	height: 24,
  },
  '& .MuiSwitch-track': {
	borderRadius: 32 / 2,
	backgroundColor: '#CBD3DC',
	opacity: 1,
	transition: theme.transitions.create(['background-color'], {
	  duration: 500,
	}),
  },
}));

export default function CenteredGrid() {
	return (
		<>
			<Header />
				<div className="icon-wrapper">
					
				</div>
			<Container 
				className="bodyContainer no-header"
				maxWidth={false}>
				<Grid container spacing={2}>
					
					<Grid item xs={12}>
						<div className="my-account-wrapper">
							<Grid container spacing={2} className="flex-height-wrapper">
								<Grid item xs={3}>
									<h2 className="my-account-header"><img className="my-account-header-icon" src={HeaderIcon} alt="List" />My Account</h2>
									<ul className="my-account-menu">
										<li className="profile"><img className="profile-menu-icon" src={ProfileMenuIcon} alt="Profile" /><a href="">Profile</a></li>
										<li className="vehicles"><img className="vehicles-menu-icon" src={VehiclesMenuIcon} alt="Vehicles" /><a href="">Vehicles</a></li>
										<li className="cards"><img className="cards-menu-icon" src={CardsMenuIcon} alt="Cards" /><a href="">Cards</a></li>
										<li className="car-parks"><img className="car-parks-menu-icon" src={CarParksMenuIcon} alt="Car Parks" /><a href="">Car Parks</a></li>
										<li className="subscriptions"><img className="subscriptions-menu-icon" src={SubsMenuIcon} alt="Subscriptions" /><a href="">Subscriptions</a></li>
										<li className="settings"><img className="settings-menu-icon" src={SettingsMenuIcon} alt="Settings" /><a className="current" href="">Settings</a></li>
										<li className="security"><img className="security-menu-icon" src={SecurityMenuIcon} alt="Security" /><a href="">Security</a></li>
										<li className="forget-me"><img className="forget-me-menu-icon" src={ForgetMeMenuIcon} alt="Forget Me" /><a href="">Forget Me</a></li>
									</ul>
								</Grid>
								
								<Grid item xs={9} className="bordered-column">
									<div className="sub-header-wrapper">
										<h3 className="my-account-sub-header">Settings</h3>
									</div>
									<div className="app-notifications-wrapper">
										<p>Allow app notifications</p>
										<div className="switch-wrapper">
											<FormGroup>
										  	<FormControlLabel className="custom-switch"
												control={<IOSSwitch />}
												label=""
												labelPlacement="start"
										  	/>
											</FormGroup>
										</div>
									</div>
									
								</Grid>
							</Grid>
						</div>

					</Grid>

				</Grid>
				
			</Container>
			
		</>
	);
}