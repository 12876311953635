import Grid from '@mui/material/Grid';
import NueCardSelected from '../components/nuecard-selected';
import CarParkFranklin from '../assets/images/nuevotek-car-park-tile-01.jpg';
  
export default function Filters() {
	return <div className="selection selected-park cardbox">
		
		<Grid container spacing={2} className="full-height">
			<Grid item xs={12} className="full-height">
				<NueCardSelected 
					title="Nuevotek Parking"
					parkid="NP"
					address="50 Grenfell Street, Adelaide SA 5000"
					subtitle="Grenfell Street Carpark in Convenient city location"
					image={CarParkFranklin}
					km="1.05km"
					price="$5 per day"
					carsize="2.75m"
					tariffs=""
					hours=""
					amenities=""
				/>
			</Grid>
		</Grid>
		
	</div>
}