import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Header from "../components/header-step-five";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import SelectionTotal from "../components/selection-total";
import CcardIconVisa from '../assets/svg/ccard-icon-visa.svg';
import CcardIconMcard from '../assets/svg/ccard-icon-mcard.svg';
import CcardIconAmex from '../assets/svg/ccard-icon-amex.svg';

export default function CenteredGrid() {
	return (
		<>
			<Header />
				<div className="icon-wrapper">
					
				</div>
			<Container 
				className="bodyContainer"
				maxWidth={false}>
				<Grid container spacing={2}>
					<Grid item xs={3}>
						<SelectionTotal />
					</Grid>
					
					<Grid item xs={9}>
						<div className="address-wrapper your-details-wrapper">
							<div className="form-row">
								<div className="form-element-wrapper">
									<h2 className="address-header">Your Details</h2>
								</div>
								<div className="form-element-wrapper align-right">
									<a href="/Eleven/" className="sign-in-link">SIGN IN</a>
								</div>
							</div>
							<div className="form-row">
								<div className="form-element-wrapper first-name">
									<FormControl variant="outlined" fullWidth className="two-line-input">
										<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
										  FIRST NAME
										</InputLabel>
										<OutlinedInput
											defaultValue="- "
											id="first-name-input"
											size="medium"
										/>
									</FormControl>
								</div>
								<div className="form-element-wrapper last-name">
									<FormControl variant="outlined" fullWidth className="two-line-input">
										<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
										  LAST NAME
										</InputLabel>
										<OutlinedInput
											defaultValue="- "
											id="last-name-input"
											size="medium"
										/>
									</FormControl>
								</div>
								<div className="form-element-wrapper business">
									<FormControl variant="outlined" fullWidth className="two-line-input">
										<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
										  BUSINESS
										</InputLabel>
										<OutlinedInput
											defaultValue="- "
											id="business-input"
											size="medium"
										/>
									</FormControl>
								</div>
							</div>
							<div className="form-row">
								<div className="form-element-wrapper email">
									<FormControl variant="outlined" fullWidth className="two-line-input">
										<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
										  EMAIL
										</InputLabel>
										<OutlinedInput
											defaultValue="- "
											id="email-input"
											size="medium"
										/>
									</FormControl>
								</div>
								<div className="form-element-wrapper mobile">
									<FormControl variant="outlined" fullWidth className="two-line-input">
										<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
										  MOBILE
										</InputLabel>
										<OutlinedInput
											defaultValue="- "
											id="mobile-input"
											size="medium"
										/>
									</FormControl>
								</div>
							</div>
						</div>
						
						<div className="address-wrapper your-details-wrapper">
							<div className="form-row">
								<div className="form-element-wrapper">
									<h2 className="address-header">Vehicle</h2>
								</div>
							</div>
							<div className="form-row">
								<div className="form-element-wrapper vehicle-make">
									<FormControl variant="outlined" fullWidth className="two-line-input">
										<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
										  VEHICLE MAKE
										</InputLabel>
										<OutlinedInput
											defaultValue="- "
											id="vehicle-make-input"
											size="medium"
										/>
									</FormControl>
								</div>
								<div className="form-element-wrapper lic-plate">
									<FormControl variant="outlined" fullWidth className="two-line-input">
										<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
										  LIC PLATE
										</InputLabel>
										<OutlinedInput
											defaultValue="- "
											id="lic-plate-input"
											size="medium"
										/>
									</FormControl>
								</div>
							</div>
						</div>
						
						<div className="address-wrapper your-details-wrapper">
							<div className="form-row">
								<div className="form-element-wrapper">
									<h2 className="address-header">Payment</h2>
								</div>
								<div className="form-element-wrapper align-right credit-cards">
									<div className="ccard-wrapper visa-icon"><img className="ccard-icon" src={CcardIconVisa} alt="VISA" /></div>
									<div className="ccard-wrapper mcard-icon"><img className="ccard-icon" src={CcardIconMcard} alt="MasterCard" /></div>
									<div className="ccard-wrapper amex-icon"><img className="ccard-icon" src={CcardIconAmex} alt="AMEX" /></div>
								</div>
							</div>
							<div className="form-row">
								<div className="form-element-wrapper name-on-card">
									<FormControl variant="outlined" fullWidth className="two-line-input">
										<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
										  NAME ON CARD
										</InputLabel>
										<OutlinedInput
											defaultValue="- "
											id="name-on-card-input"
											size="medium"
										/>
									</FormControl>
								</div>
								<div className="form-element-wrapper card-number">
									<FormControl variant="outlined" fullWidth className="two-line-input">
										<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
										  CARD NUMBER
										</InputLabel>
										<OutlinedInput
											defaultValue="- "
											id="card-number-input"
											size="medium"
										/>
									</FormControl>
								</div>
								<div className="form-element-wrapper expiry">
									<FormControl variant="outlined" fullWidth className="two-line-input">
										<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
										  EXPIRY
										</InputLabel>
										<OutlinedInput
											defaultValue="- "
											id="expiry-input"
											size="medium"
										/>
									</FormControl>
								</div>
								<div className="form-element-wrapper cvv">
									<FormControl variant="outlined" fullWidth className="two-line-input">
										<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
										  CVV
										</InputLabel>
										<OutlinedInput
											defaultValue="- "
											id="cvv-input"
											size="medium"
										/>
									</FormControl>
								</div>
							</div>
						</div>
					</Grid>
				</Grid>
				
				<Grid container spacing={2} className="next-button-wrapper">
					<Grid item xs={12}>
						<FormControl className="nextButtonControl">
							<Button 
								className="nextButton" 
								size="small" 
								variant="contained">
								Next
							</Button>
						</FormControl>
					</Grid>
				</Grid>
			</Container>
			
		</>
	);
}