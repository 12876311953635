import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import Header from "../components/header-processing";
import ProcessingWheel from "../assets/svg/processing-wheel-static.svg";

export default function CenteredGrid() {
	return (
		<>
			<Header />
				<div className="icon-wrapper">
					
				</div>
			<Container 
				className="bodyContainer"
				maxWidth={false}>
				<Grid container spacing={2}>
					
					<Grid item xs={12}>
						<div className="processing-wrapper">
							<img className="ProcessingWheel" src={ProcessingWheel} />
							<h3>Processing...</h3>
							<p>Please wait as we confirm your park.</p>
						</div>
					</Grid>
				</Grid>
				
			</Container>
			
		</>
	);
}