import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import Header from "../components/header-step-three";
import Selection from "../components/selection";
import NueAddon from "../components/nueaddon";
import AddOnImageWash from '../assets/images/nuevotek-add-on-tile-01.jpg';
import AddOnImageBudget from '../assets/images/nuevotek-add-on-tile-02.jpg';
import AddOnImagePremium from '../assets/images/nuevotek-add-on-tile-03.jpg';

export default function CenteredGrid() {
	return (
		<>
			<Header />
				<div className="icon-wrapper">
					
				</div>
			<Container 
				className="bodyContainer"
				maxWidth={false}>
				<Grid container spacing={2}>
					<Grid item xs={3}>
						<Selection />
					</Grid>
					
					<Grid item xs={3}>
						<NueAddon 
							title="Car Wash - Wash & Wax"
							subtitle="Magic Car Wash"
							price="$45.00"
							colour="#8769CC"
							image={AddOnImageWash}
							info="Wheels cleaned, hand wash, polish, glass outside."
							moreinfourl=""
						/>
					</Grid>
					
					<Grid item xs={3}>
						<NueAddon 
							title="4 Cents off Petrol"
							subtitle="Caltex"
							price="$Free"
							colour="#8769CC"
							image={AddOnImageBudget}
							info="Save 4c per litre off advertised price of fuel."
							moreinfourl=""
						/>
					</Grid>
						
					<Grid item xs={3}>
						<NueAddon
							title="Apple AirPod Pros"
							subtitle="JB Hi-Fi"
							price="$270.00"
							colour="#8769CC"
							image={AddOnImagePremium}
							info="View more information about this product."
							moreinfourl=""
						/>
					</Grid>
				</Grid>
				
				<Grid container spacing={2} className="next-button-wrapper">
					<Grid item xs={12}>
						<FormControl className="nextButtonControl">
							<Button 
								className="nextButton" 
								size="small" 
								variant="contained">
								Next
							</Button>
						</FormControl>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}