import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import Header from "../components/header";
import ConfirmationIcon from "../assets/svg/icon-basket.svg";

export default function CenteredGrid() {
	return (
		<>
			<Header />
				<div className="icon-wrapper">
					
				</div>
			<Container 
				className="bodyContainer no-header"
				maxWidth={false}>
				<Grid container spacing={2}>
					
					<Grid item xs={12}>
						<div className="confirmation-wrapper">
							<div className="confirmation-inner">
								
								<Grid container spacing={1} className="confirmation-order-title">
									<Grid item xs={12} md={12}>
										<span className="confirmationTitle"><img className="HeaderConfirmationIcon" src={ConfirmationIcon} />Order Confirmation</span>
									</Grid>
								</Grid>
								
								<Grid container spacing={1} className="confirmation-order-header">
									<Grid item xs={12} md={9}>
										<p className="confirmation-order-date">9th Feb 23</p>
									</Grid>
									<Grid item xs={12} md={3}>
										<p className="confirmation-order-number">Order No. P4102351</p>
									</Grid>
									<Grid item xs={12} md={12}>
										<p className="confirmation-order-car-park"><strong>CarePark Franklin</strong></p>
										<p className="confirmation-order-date-time">16th Nov 23 09:00 - 20th Nov 23 14:00</p>
										<p className="confirmation-order-vehicle">Volkswagen S45278A</p>
									</Grid>
								</Grid>
								
								<Grid container spacing={1} className="confirmation-order-body">
									<Grid item xs={12} md={9}>
										<p className="confirmation-order-item">Day Parker - Standard</p>
									</Grid>
									<Grid item xs={12} md={3}>
										<p className="confirmation-order-price">$95.00</p>
									</Grid>
									<Grid item xs={12} md={9}>
										<p className="confirmation-order-item">Car Wash - Wash & Wax</p>
									</Grid>
									<Grid item xs={12} md={3}>
										<p className="confirmation-order-price">$45.00</p>
									</Grid>
									<Grid item xs={12} md={9}>
										<p className="confirmation-order-item">SMS - 2hr Booking Reminder</p>
									</Grid>
									<Grid item xs={12} md={3}>
										<p className="confirmation-order-price">$0.50</p>
									</Grid>
									<Grid item xs={12} md={9}>
										<p className="confirmation-order-item">Service Charge (5%)</p>
									</Grid>
									<Grid item xs={12} md={3}>
										<p className="confirmation-order-price">$7.02</p>
									</Grid>
									<Grid item xs={12} md={9}>
										<p className="confirmation-order-item"><strong>Sub Total</strong> (inc GST):</p>
									</Grid>
									<Grid item xs={12} md={3}>
										<p className="confirmation-order-price"><strong>$147.52</strong></p>
									</Grid>
									<Grid item xs={12} md={9}>
										<p className="confirmation-order-item">Congestion Levy (15%)</p>
									</Grid>
									<Grid item xs={12} md={3}>
										<p className="confirmation-order-price"><strong>$22.13</strong></p>
									</Grid>
								</Grid>
								
								<Grid container spacing={1} className="confirmation-order-footer">
									<Grid item xs={12} md={9}>
										<p className="confirmation-order-total"><strong>Total</strong></p>
									</Grid>
									<Grid item xs={12} md={3}>
										<p className="confirmation-order-total-price"><strong>$169.95</strong></p>
									</Grid>
								</Grid>
								
								<Grid container spacing={1} className="confirmation-order-ccard">
									<Grid item xs={12} md={12}>
										<p className="confirmation-order-cccard">VISA   **** **** **** **45    Exp. 12/24</p>
									</Grid>
								</Grid>
							</div>
						</div>
					</Grid>
				</Grid>
				
			</Container>
			
		</>
	);
}