import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Link } from "react-router-dom";
import Header from "../components/header-step-one"
import NuevotekHomeImg from '../assets/images/nuevotek-carpark-pic.png';

const useStyles = makeStyles((theme) => ({
    root: {
    	flexGrow: 1,
    }
}));

export default function CenteredGrid() {
      const classes = useStyles();
    return <>
		<Header />
		
		<Container className="entryPageBG">
			<img src={NuevotekHomeImg} alt="Nuevotek Main Image" />
		</Container>
	</>
}


