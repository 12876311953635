import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import NuevotekLogo from '../assets/svg/nuevopark-logo.svg';

export default function Header() {
	return <>
		<div className="headerBackground"></div>
		<Container className="headerWrapper">
			<div className="topHeader">
				<div className="headerTitle">
					<h2>Find the best parking deals online<br/>
					& reserve your space for peace of mind.</h2>
				</div>
				<div className="headerNavigationWrapper">
					<img className="headerLogo" src={NuevotekLogo} alt="Nuevopark" />
					<div className="headerNavigation">
						<a href="/one/" className="navLink">Home</a>
						<a href="/nine/" className="navLink">Manage my Booking</a>
						<a href="/twelve/" className="navLink">Create an Account</a>
						<a href="/eleven/" className="navLink">Sign In</a>
					</div>
				</div>
			</div>
			
			<div className="bookingControls custom-input-fields">
				<h1 className="manage-booking">Find an Active Event</h1>
				<div className="manage-buttons">
					<div className="booking-ref-input grid-element">
						<FormControl variant="outlined" fullWidth className="two-line-input">
							<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
							  BOOKING REF
							</InputLabel>
							<OutlinedInput
								defaultValue="- "
								id="booking-ref-input"
								size="medium"
							/>
						</FormControl>
					</div>
					<div className="first-name-input grid-element">
						<FormControl variant="outlined" fullWidth className="two-line-input">
							<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
							  FIRST NAME
							</InputLabel>
							<OutlinedInput
								defaultValue="- "
								id="first-name-input"
								size="medium"
							/>
						</FormControl>
					</div>
					<div className="last-name-input grid-element">
						<FormControl variant="outlined" fullWidth className="two-line-input">
							<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
							  LAST NAME
							</InputLabel>
							<OutlinedInput
								defaultValue="- "
								id="last-name-input"
								size="medium"
							/>
						</FormControl>
					</div>
					
					<div className="mobile-input grid-element">
						<FormControl variant="outlined" fullWidth className="two-line-input">
							<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
							  MOBILE
							</InputLabel>
							<OutlinedInput
								defaultValue="- "
								id="mobile-input"
								size="medium"
							/>
						</FormControl>
					</div>
					
					<div className="search-input grid-element">
						<FormControl className="searchButtonControl">
							<Button 
								className="searchButton" 
								size="large" 
								variant="contained">
								Search
							</Button>
						</FormControl>
					</div>
					
				</div>
			</div>
		</Container>
	</>
}