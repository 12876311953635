import Grid from '@mui/material/Grid';
import NuecardImage from '../assets/images/nuecard-image.png';
import Button from '@mui/material/Button';
import CancelIcon from '../assets/svg/icon-cancel.svg';
import AmendIcon from '../assets/svg/icon-amend.svg';
import ExtendIcon from '../assets/svg/icon-extend.svg';
import RemoveIcon from '../assets/svg/icon-cross.svg';
import AddIcon from '../assets/svg/icon-tick.svg';
import { styled } from '@mui/material/styles';

const BlueButton = styled( Button )({
  color: '#ffffff;',
  borderColor: '#009eff',
  backgroundColor: '#009eff',
  width: 'auto',
  textTransform: 'none',
  fontSize: '0.875rem',
  letterSpacing: '0',
  borderRadius: '3em',
  padding: '3px 15px',
  marginRight: '15px',
  });
  
export default function NueOffer( props: any ) {
	let title = props.title;
	let subtitle = props.subtitle;
	let price = props.price;
	let Image = props.image;
	let colour = props.colour;
	let info = props.info;
	let point1 = props.point1;
	let point2 = props.point2;
	let point3 = props.point3;
	let point4 = props.point4;
		
	return (
		<div className="nuecard cardbox">
			<div className="nuecard-header">
				<div className="nueoffer-title">
					{title}
				</div>
				<div className="nueoffer-subtitle">
					{subtitle}
				</div>
				<div className="nueoffer-price">
					{price}
				</div>
			</div>
			<Grid container spacing={0}>
				<Grid item xs={4}>
					<div className="nueoffer-image" style={{backgroundColor: colour}}>
						<img src={Image} />
					</div>
				</Grid>
				<Grid item xs={8}>
					<div className="nueoffer-details">
						<p>{info}</p>
						<ul>
							<li>{point1}</li>
							<li>{point2}</li>
							<li>{point3}</li>
							<li>{point4}</li>
						</ul>
					</div>
					<div className="nueoffer-info">
						<div className="nueoffer-info-item">
							<img className="cancel-icon" src={CancelIcon} />Cancel<img className="remove-icon" src={RemoveIcon} />
						</div>
						<div className="nueoffer-info-item">
							<img className="amend-icon" src={AmendIcon} />Amend<img className="add-icon" src={AddIcon} />
						</div>
						<div className="nueoffer-info-item">
							<img className="extend-icon" src={ExtendIcon} />Extend<img className="add-icon" src={AddIcon} />
						</div>
						<div className="nueoffer-info-item">
							<a href="#">More info</a>
						</div>
						<div className="nueoffer-info-item">
							<a href="#">Terms &amp; Conditions</a>
						</div>
						<div className="nueoffer-info-item last-item">
							<BlueButton 
								variant="outlined">
								Select Offer
							</BlueButton>
						</div>
					</div>
				</Grid>
			</Grid>
		</div>
	);
}