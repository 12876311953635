import Container from '@mui/material/Container';
import NuevotekLogo from '../assets/svg/nuevopark-logo.svg';

export default function Header() {
	return <>
		<div className="headerBackground"></div>
		<Container className="headerWrapper">
			<div className="topHeader">
				<div className="headerTitle">
					<h2>The best parking deals online</h2>
				</div>
				<div className="headerNavigationWrapper">
					<img className="headerLogo" src={NuevotekLogo} alt="NuevoPark" />
					<div className="headerNavigation">
						<a href="/one/" className="navLink">Home</a>
						<a href="/nine/" className="navLink">My Activity</a>
						<a href="/thirteen/" className="navLink">My Account</a>
						<a href="/eleven/" className="navLink">Sign Out</a>
					</div>
				</div>
			</div>
			
		</Container>
	</>
}