import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Header from "../components/header-step-four";
import FormControl from '@mui/material/FormControl';
import SelectionReminders from "../components/selection-reminders";
import NueAddon from "../components/nueaddon";
import ExpiryReminder01 from '../assets/images/nuevotek-reminder-tile-01.png';
import ExpiryReminder02 from '../assets/images/nuevotek-reminder-tile-02.png';
import EmailReminder from '../assets/images/nuevotek-reminders-tile-03.png';

export default function CenteredGrid() {
	return (
		<>
			<Header />
				<div className="icon-wrapper">
					
				</div>
			<Container 
				className="bodyContainer"
				maxWidth={false}>
				<Grid container spacing={2}>
					<Grid item xs={3}>
						<SelectionReminders />
					</Grid>
					
					<Grid item xs={9}>
						<div className="offer-wrapper">
							<NueAddon 
								title="30 Minutes Booking Expiry Reminder"
								subtitle="App notification reminder of booking expiry"
								price="$ 0.50"
								colour="#b9c5d7"
								image={ExpiryReminder01}
								info="Avoid overstay fees with a reminder sent to the mobile phone on the booking 30 minutes ahead of time."
								moreinfourl=""
							/>
						</div>
						<div className="offer-wrapper selected">
							<NueAddon
								title="30 Minutes Booking Expiry Reminder"
								subtitle="SMS notification reminder of booking expiry"
								price="$ 0.25"
								colour="#b9c5d7"
								image={ExpiryReminder02}
								info="Avoid overstay fees with a reminder sent to the mobile phone on the booking 30 minutes ahead of time."
								moreinfourl=""
							/>
						</div>
					</Grid>
						
				</Grid>
				
				<Grid container spacing={2} className="next-button-wrapper">
					<Grid item xs={12}>
						<FormControl className="nextButtonControl">
							<Button 
								className="nextButton" 
								size="small" 
								variant="contained">
								Next
							</Button>
						</FormControl>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}