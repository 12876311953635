import Grid from '@mui/material/Grid';
import NuecardImage from '../assets/images/nuecard-image.png';
import Button from '@mui/material/Button';
import DistanceWalkIcon from '../assets/svg/icon-walking.svg';
import PriceFromIcon from '../assets/svg/icon-price.svg';
import ClearanceHeightIcon from '../assets/svg/icon-height.svg';
import TariffsIcon from '../assets/svg/icon-tariffs.svg';
import TradinghoursIcon from '../assets/svg/icon-tradinghrs.svg';
import AmenitiesIcon from '../assets/svg/icon-amenities.svg';
import PinIcon from '../assets/svg/icon-pin-3.svg';
import HeartIcon from '../assets/svg/icon-heart.svg';
import { styled } from '@mui/material/styles';

const BlueButton = styled( Button )({
  color: '#ffffff;',
  borderColor: '#009eff',
  backgroundColor: '#009eff',
  width: 'auto',
  textTransform: 'none',
  fontSize: '0.875rem',
  letterSpacing: '0',
  borderRadius: '3em',
  padding: '3px 15px',
  });
  
export default function NueCard( props: any ) {
	let title = props.title;
	let parkid = props.parkid;
	let address = props.address;
	let subtitle = props.subtitle;
	let Image = props.image;
	let km = props.km;
	let price = props.price;
	let carsize = props.carsize;
	let tariffs = props.tariffs;
	let hours = props.hours;
	let amenities = props.amenities;
	
	return (
		<div className="nuecard cardbox">
			<div className="nuecard-header">
				<div className="nuecard-title">
					{title} <span className="nuecard-id">(ID. {parkid})</span>
				</div>
				<div className="nuecard-address">
					{address} <a className="map-icon" href="#"><img className="open-map-icon" src={PinIcon} /></a>
				</div>
				<a className="favs-button" href="#">
					<svg className="favs-icon" id="heart" xmlns="http://www.w3.org/2000/svg" width="19.92" height="17.07" viewBox="0 0 19.92 17.07"><path d="m9.47,16.87l-6.94-6.69c-.09-.08-2.53-2.31-2.53-4.98C0,1.95,1.99,0,5.31,0c1.95,0,3.77,1.53,4.65,2.4.88-.87,2.7-2.4,4.65-2.4,3.32,0,5.31,1.95,5.31,5.2,0,2.67-2.45,4.9-2.55,5l-6.92,6.67c-.13.13-.31.2-.49.2s-.36-.07-.49-.2Z" stroke-width="0"/></svg>
				</a>
			</div>
			<Grid container spacing={0}>
				<Grid item xs={4}>
					<div className="nuecard-image">
						<img src={Image} />
					</div>
				</Grid>
				<Grid item xs={8}>
					<div className="nuecard-content">
						<div className="nuecard-subtitle">
							{subtitle}
						</div>
						
						<div className="nuecard-details">
							<div className="nuecard-details-item price">
								<div className="item-icon-wrapper">
									<img className="price-icon" src={PriceFromIcon} />
								</div>
								From: {price}
							</div>
							<div className="nuecard-details-item km">
								<div className="item-icon-wrapper">
									<img className="walk-icon" src={DistanceWalkIcon} />
								</div>
								{km}
							</div>
							<div className="nuecard-details-item carsize">
								<div className="item-icon-wrapper">
									<img className="height-icon" src={ClearanceHeightIcon} />
								</div>
								{carsize}
							</div>
						</div>
					</div>
					<div className="nuecard-info">
						<div className="nuecard-info-item">
							<img className="amenities-icon" src={AmenitiesIcon} />Amenities
						</div>
						<div className="nuecard-info-item">
							<img className="trading-hrs-icon" src={TradinghoursIcon} />Trading Hrs
						</div>
						<div className="nuecard-info-item">
							<img className="tariffs-icon" src={TariffsIcon} />Tariffs
						</div>
						<div className="nuecard-info-item last-item">
							<BlueButton 
								variant="outlined">
								View Offers
							</BlueButton>
						</div>
					</div>
				</Grid>
			</Grid>
		</div>
	);
}