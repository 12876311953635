import Grid from '@mui/material/Grid';
import SelectionIcon from '../assets/svg/icon-basket.svg';
import EditIcon from '../assets/svg/icon-edit-2.svg';
  
export default function Filters() {
	return <div className="selection cardbox">
		<Grid container spacing={1} className="selectionbox">
			<Grid item md={12} className="selection-title-row">
				<span className="selectionTitle"><img className="HeaderSelectionIcon" src={SelectionIcon} alt="Selection" />Your Selection</span>
			</Grid>
			<Grid item xs={12} md={8}>
				<p className="selection-line-item-title">Day Parker - Standard</p>
			</Grid>
			<Grid item xs={12} md={3}>
				<p className="selection-line-item-price">$95.00</p>
			</Grid>
			<Grid item xs={12} md={1}>
				<img className="EditSelectionIcon" src={EditIcon} alt="Edit Seclection" />
			</Grid>
			<Grid item xs={12} md={8}>
				<p className="selection-line-item-title">Service Charge (5%)</p>
			</Grid>
			<Grid item xs={12} md={3}>
				<p className="selection-line-item-price">$4.75</p>
			</Grid>
			<Grid item xs={12} md={1}>
			</Grid>
			<Grid item xs={12} md={8}>
				<p className="selection-line-item-title"><strong>Sub Total</strong> (inc GST):</p>
			</Grid>
			<Grid item xs={12} md={3}>
				<p className="selection-line-item-price"><strong>$99.75</strong></p>
			</Grid>
			<Grid item xs={12} md={1}>
			</Grid>
			<Grid item xs={12} md={8}>
				<p className="selection-line-item-title">Congestion Levy (15%)</p>
			</Grid>
			<Grid item xs={12} md={3}>
				<p className="selection-line-item-price">$14.96</p>
			</Grid>
			<Grid item xs={12} md={1}>
			</Grid>
		</Grid>
		<Grid container spacing={1} className="selectionbox totals">
			<Grid item xs={12} md={9}>
				<p className="selection-line-item-total"><strong>Total</strong></p>
			</Grid>
			<Grid item xs={12} md={2}>
				<p className="selection-line-item-total-price"><strong>$114.80</strong></p>
			</Grid>
			<Grid item xs={12} md={1}>
			</Grid>
		</Grid>
	</div>
}