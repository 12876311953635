import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const BlueButton = styled( Button )({
  color: '#ffffff;',
  borderColor: '#009eff',
  backgroundColor: '#009eff',
  width: 'auto',
  textTransform: 'none',
  fontSize: '0.875rem',
  letterSpacing: '0',
  borderRadius: '3em',
  padding: '3px 15px',
  marginRight: '15px',
  });
  
export default function nueaddon( props: any ) {
	let title = props.title;
	let subtitle = props.subtitle;
	let price = props.price;
	let Image = props.image;
	let colour = props.colour;
	let info = props.info;
		
	return (
		<div className="nuecard cardbox">
			<div className="nuecard-header">
				<div className="nueaddon-title">
					{title}
				</div>
				<div className="nueaddon-subtitle">
					{subtitle}
				</div>
				<div className="nueaddon-price">
					{price}
				</div>
			</div>
			<Grid container spacing={0}>
				<Grid item xs={4}>
					<div className="nueaddon-image" style={{backgroundColor: colour}}>
						<img src={Image} alt="Selected Offer" />
					</div>
				</Grid>
				<Grid item xs={8} className="nueaddon-right-col">
					<div className="nueaddon-details">
						{info}
					</div>
					<div className="nueaddon-info">
						<div className="nueaddon-info-item">
							<a href="#">More info</a>
						</div>
						<div className="nueaddon-info-item">
							<a href="#">Terms &amp; Conditions</a>
						</div>
						<div className="nueaddon-info-item last-item">
							<BlueButton 
								variant="outlined">
								Select
							</BlueButton>
						</div>
					</div>
				</Grid>
			</Grid>
		</div>
	);
}