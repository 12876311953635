import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Header from "../components/header-step-two"
import SelectedPark from "../components/selected-park";
import OfferAdel500 from '../assets/images/nuevotek-offer-adl500.jpg';
import OfferAdelFringe from '../assets/images/nuevotek-offer-adelfringe.jpg';
import NueOffer from "../components/nueoffer";

export default function CenteredGrid() {
	return (
		<>
			<Header />
				<div className="icon-wrapper">
					
				</div>
			<Container 
				className="bodyContainer"
				maxWidth={false}>
				<Grid container spacing={2}>
					<Grid item xs={3}>
						<SelectedPark />
					</Grid>
					
					<Grid item xs={9}>
						<NueOffer
							title="Early Bird Parking Weekdays"
							subtitle="The cheapest way to park on business days"
							price="$12.00"
							info="Enter before 8:00am and leave after 5:00pm and pay a lower rate every time."
							point1="Safe and Secure"
							point2="Near Shops"
							point3="Convenient Access"
							point4="Flexible Conditions"
							colour="#000000"
							image={OfferAdel500}
							moreinfourl=""
						/>
						<NueOffer
							title="Early Bird Parking Weekdays"
							subtitle="The cheapest way to park on business days"
							price="$12.00"
							info="Enter before 8:00am and leave after 5:00pm and pay a lower rate every time."
							point1="Safe and Secure"
							point2="Near Shops"
							point3="Convenient Access"
							point4="Flexible Conditions"
							colour="#e280a8"
							image={OfferAdelFringe}
							moreinfourl=""
						/>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}