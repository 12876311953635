import Container from '@mui/material/Container';
import NuevotekLogo from '../assets/svg/nuevopark-logo.svg';

export default function Header() {
	return <>
		<div className="headerBackground"></div>
		<Container className="headerWrapper">
			<div className="topHeader">
				<div className="headerTitle">
					<h2>Find the best parking deals online<br/>
					& reserve your space for peace of mind.</h2>
				</div>
				<div className="headerNavigationWrapper">
					<img className="headerLogo" src={NuevotekLogo} alt="NuevoPark" />
					<div className="headerNavigation">
						<a href="/one/" className="navLink">Home</a>
						<a href="/nine/" className="navLink">Manage my Booking</a>
						<a href="/twelve/" className="navLink">Create an Account</a>
						<a href="/eleven/" className="navLink">Sign In</a>
					</div>
				</div>
			</div>
			
		</Container>
	</>
}