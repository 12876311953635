import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Header from "../components/header-my-account";
import HeaderIcon from '../assets/svg/my-activity-heading-icon.svg';
import ActiveParksMenuIcon from '../assets/svg/my-acc-parks-icon-sml.svg';
import BookingsMenuIcon from '../assets/svg/my-acc-bookings-icon-sml.svg';
import LeasesMenuIcon from '../assets/svg/my-acc-lease-icon-lrg.svg';
import EntitlementsMenuIcon from '../assets/svg/my-acc-entitlements-icon-sml.svg';
import HistoryMenuIcon from '../assets/svg/my-acc-history-icon-sml.svg';

export default function CenteredGrid() {
	return (
		<>
			<Header />
				<div className="icon-wrapper">
					
				</div>
			<Container 
				className="bodyContainer no-header"
				maxWidth={false}>
				<Grid container spacing={2}>
					
					<Grid item xs={12}>
						<div className="my-account-wrapper">
							<Grid container spacing={2} className="flex-height-wrapper">
								<Grid item xs={3}>
									<h2 className="my-account-header"><img className="my-account-header-icon" src={HeaderIcon} alt="List" />My Activity</h2>
									<ul className="my-activity-menu">
										<li className="active-parks"><img className="active-parks-menu-icon" src={ActiveParksMenuIcon} alt="Parks" /><a href="">Active Parks</a></li>
										<li className="bookings"><img className="bookings-menu-icon" src={BookingsMenuIcon} alt="Bookings" /><a className="current" href="">Bookings</a></li>
										<li className="leases"><img className="leases-menu-icon" src={LeasesMenuIcon} alt="Leases" /><a href="">Leases</a></li>
										<li className="entitlements"><img className="entitlements-menu-icon" src={EntitlementsMenuIcon} alt="Parks" /><a href="">Entitlements</a></li>
										<li className="history"><img className="history-menu-icon" src={HistoryMenuIcon} alt="Parks" /><a href="">History</a></li>
									</ul>
								</Grid>
								<Grid item xs={9} className="bordered-column">
									<h3 className="my-activity-sub-header">Bookings</h3>
									<div className="active-park-item">
										<img className="bookings-menu-icon" src={BookingsMenuIcon} alt="Bookings" />
										<div className="active-park-booking">GMF0G245</div>
										<span className="active-park-divider">|</span>
										<div className="active-park-dates">10/11/23 08:30am - 14/11/23 5:30pm</div>
										<span className="active-park-divider">|</span>
										<div className="active-park-id">457 Franklin (ID. 47235)</div>
										<div className="active-park-status">Status: Reserved</div>
									</div>
									
									<div className="active-park-item">
										<img className="bookings-menu-icon" src={BookingsMenuIcon} alt="Bookings" />
										<div className="active-park-booking">GMF0G300</div>
										<span className="active-park-divider">|</span>
										<div className="active-park-dates">20/11/23 09:30am - 20/11/23 5:00pm</div>
										<span className="active-park-divider">|</span>
										<div className="active-park-id">457 Franklin (ID. 47235)</div>
										<div className="active-park-status">Status: Reserved</div>
									</div>
									
									<div className="active-park-item">
										<img className="bookings-menu-icon" src={BookingsMenuIcon} alt="Bookings" />
										<div className="active-park-booking">GMF0G471</div>
										<span className="active-park-divider">|</span>
										<div className="active-park-dates">26/11/23 08:30am - 28/11/23 5:30pm</div>
										<span className="active-park-divider">|</span>
										<div className="active-park-id">457 Franklin (ID. 47235)</div>
										<div className="active-park-status">Status: Reserved</div>
									</div>
								</Grid>
							</Grid>
						</div>

					</Grid>

				</Grid>
				
			</Container>
			
		</>
	);
}