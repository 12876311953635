import * as React from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Header from "../components/header-step-one"
import NuevotekHomeImg from '../assets/images/nuevotek-carpark-pic.png';
import SignInIcon from '../assets/svg/sign-in-icon.svg';
import IconButton from '@mui/material/IconButton';
import Visibility from '../assets/svg/show-password-icon.svg';
import VisibilityOff from '../assets/svg/hide-password-icon.svg';

export default function InputAdornments() {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
	event.preventDefault();
  };
  
    return <>
	
		<div className="sign-in-modal">
			<Grid container spacing={1} className="modal-grid">
				<Grid item xs={12} md={12}>
					<h2 className="sign-in-header"><img className="sign-in-icon" src={SignInIcon} alt="Sign In" />Sign In</h2>
				</Grid>
				<Grid item xs={12} md={12}>
					<FormControl variant="outlined" fullWidth className="two-line-input">
						<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
						  EMAIL
						</InputLabel>
						<OutlinedInput
							defaultValue="- "
							id="email-input"
							size="medium"
						/>
					</FormControl>
				</Grid>
				<Grid item xs={12} md={12}>
						<FormControl variant="outlined" fullWidth className="two-line-input">
					  	<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">PASSWORD</InputLabel>
					  	<OutlinedInput
							id="outlined-adornment-password"
							type={showPassword ? 'text' : 'password'}
							endAdornment={
						  	<InputAdornment position="end">
								<IconButton
							  	aria-label="toggle password visibility"
							  	onClick={handleClickShowPassword}
							  	onMouseDown={handleMouseDownPassword}
							  	edge="end"
								>
							  	{showPassword ? <img src={VisibilityOff} className="show-password-icon" alt="Show Password" /> : <img src={Visibility} className="show-password-icon" alt="Show Password" />}
								</IconButton>
						  	</InputAdornment>
							}
							label="Password"
					  	/>
						</FormControl>
				</Grid>
				<Grid item xs={12} md={12}>
					<div className="signin-button-wrapper">
						<a className="forgot-password" href="/forgot-password/">Forgot Password</a>
						<FormControl className="signinButtonControl">
							<Button 
								className="signinButton" 
								size="small" 
								variant="contained">
								Sign In
							</Button>
						</FormControl>
					</div>
				</Grid>
			</Grid>
		</div>
		
		<Header />
		
		<Container className="entryPageBG">
			<img src={NuevotekHomeImg} alt="Nuevotek" />
		</Container>
	</>
}


