import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import NuevotekLogo from '../assets/svg/nuevopark-logo.svg';
import SearchIconPin from '../assets/svg/icon-pin.svg';
import SearchIconCalendar from '../assets/svg/icon-calendar.svg';
import SearchIconPromo from '../assets/svg/icon-promo.svg';

export default function Header() {
	return <>
		<div className="headerBackground"></div>
		<Container className="headerWrapper">
			<div className="topHeader">
				<div className="headerTitle">
					<h2>Find the best parking deals online<br/>
					& reserve your space for peace of mind.</h2>
				</div>
				<div className="headerNavigationWrapper">
					<img className="headerLogo" src={NuevotekLogo} alt="NuevoPark" />
					<div className="headerNavigation">
						<a href="/one/" className="navLink">Home</a>
						<a href="/nine/" className="navLink">Manage my Booking</a>
						<a href="/twelve/" className="navLink">Create an Account</a>
						<a href="/eleven/" className="navLink">Sign In</a>
					</div>
				</div>
			</div>
			
			<div className="bookingControls">
				<div className="nuevo-stepper">
					<div className="stepper-text">
						<div className="text-wrapper step-one">
							<span className="step-text active-step">DESTINATION &amp; DATES</span>
						</div>
						<div className="text-wrapper step-two">
							<span className="step-text inactive-step">SELECT PARKING OFFER</span>
						</div>
						<div className="text-wrapper step-three">
							<span className="step-text inactive-step">SELECT ADD ONS</span>
						</div>
						<div className="text-wrapper step-four">
							<span className="step-text inactive-step">SELECT REMINDERS</span>
						</div>
						<div className="text-wrapper step-five">
							<span className="step-text inactive-step">PAYMENT</span>
						</div>
					</div>
					<div className="stepper-icons">
						<div className="stepper-progress-wrapper zero-one">
							<div className="progress-bar active"></div>
						</div>
						<div className="stepper-icon-wrapper step-one">
							<span className="step-circle active">1</span>
						</div>
						<div className="stepper-progress-wrapper one-two">
							<div className="progress-bar inactive"></div>
						</div>
						<div className="stepper-icon-wrapper step-two">
							<span className="step-circle inactive">2</span>
						</div>
						<div className="stepper-progress-wrapper two-three">
							<div className="progress-bar inactive"></div>
						</div>
						<div className="stepper-icon-wrapper step-three">
							<span className="step-circle inactive">3</span>
						</div>
						<div className="stepper-progress-wrapper three-four">
							<div className="progress-bar inactive"></div>
						</div>
						<div className="stepper-icon-wrapper step-four">
							<span className="step-circle inactive">4</span>
						</div>
						<div className="stepper-progress-wrapper four-five">
							<div className="progress-bar inactive"></div>
						</div>
						<div className="stepper-icon-wrapper step-five">
							<span className="step-circle inactive">5</span>
						</div>
						<div className="stepper-progress-wrapper five-six">
							<div className="progress-bar inactive"></div>
						</div>
					</div>
				</div>
				
				<div className="bookingButtons">
					<div className="destination-input">
						<FormControl variant="outlined" fullWidth className="two-line-input">
							<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
							  DESTINATION
							</InputLabel>
							<OutlinedInput
								defaultValue="- "
								id="destination-input"
								size="medium"
								startAdornment={
									<InputAdornment position="start">
										<img className="search-pin-icon" src={SearchIconPin} alt="Search Car parks" />
									</InputAdornment>
								}
							/>
						</FormControl>
					</div>
					<div className="entry-date-input">
						<FormControl variant="outlined" fullWidth className="two-line-input">
							<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
							  ENTRY DATE/TIME
							</InputLabel>
							<OutlinedInput
								defaultValue="- "
								id="entry-input"
								size="medium"
								startAdornment={
									<InputAdornment position="start">
										<img className="search-calendar-icon" src={SearchIconCalendar} alt="Select Date" />
									</InputAdornment>
								}
							/>
						</FormControl>
					</div>
					
					<div className="exit-date-input">
						<FormControl variant="outlined" fullWidth className="two-line-input">
							<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
							  EXIT DATE/TIME
							</InputLabel>
							<OutlinedInput
								defaultValue="- "
								id="exit-input"
								size="medium"
								startAdornment={
									<InputAdornment position="start">
										<img className="search-calendar-icon" src={SearchIconCalendar} alt="Select Date" />
									</InputAdornment>
								}
							/>
						</FormControl>
	
					</div>
					
					<div className="promo-code-input">
						<FormControl variant="outlined" fullWidth className="two-line-input">
							<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
							  PROMO CODE
							</InputLabel>
							<OutlinedInput
								defaultValue="- "
								id="promo-code-input"
								size="medium"
								startAdornment={
									<InputAdornment position="start">
										<img className="search-promo-icon" src={SearchIconPromo} alt="Add promo code" />
									</InputAdornment>
								}
							/>
						</FormControl>
					</div>
					<div className="searchButton-wrapper">
						<FormControl className="searchButtonControl">
							<Button 
								className="searchButton" 
								size="small" 
								variant="contained">
								Search
							</Button>
						</FormControl>
					</div>
				</div>
			</div>
		</Container>
	</>
}