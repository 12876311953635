import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Header from "../components/header";
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Checkbox from '@mui/material/Checkbox';

export default function CenteredGrid() {
	return (
		<>
			<Header />
				<div className="icon-wrapper">
					
				</div>
			<Container 
				className="bodyContainer no-header"
				maxWidth={false}>
				<Grid container spacing={2}>
					
					<Grid item xs={12}>
						<div className="address-wrapper your-details-wrapper create-account-wrapper">
							<div className="form-row">
								<div className="form-element-wrapper">
									<h2 className="address-header">Create an Account</h2>
								</div>
							</div>
							
							<div className="form-row">
								<Grid container spacing={2}>
									<Grid item xs={3}>
										<FormControl variant="outlined" fullWidth className="two-line-input">
											<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
											  FIRST NAME
											</InputLabel>
											<OutlinedInput
												defaultValue="- "
												id="first-name-input"
												size="medium"
											/>
										</FormControl>
									</Grid>
									
									<Grid item xs={3}>
										<FormControl variant="outlined" fullWidth className="two-line-input">
											<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
										  	LAST NAME
											</InputLabel>
											<OutlinedInput
												defaultValue="- "
												id="last-name-input"
												size="medium"
											/>
										</FormControl>
									</Grid>
									
									<Grid item xs={3}>
										<FormControl variant="outlined" fullWidth className="two-line-input">
											<InputLabel htmlFor="input-with-icon-adornment" className="custom-label optional-label">
											  YEAR OF BIRTH
											</InputLabel>
											<OutlinedInput
												defaultValue="- "
												id="year-of-birth-input"
												size="medium"
											/>
										</FormControl>
									</Grid>
									
									<Grid item xs={3}>
										<FormControl variant="outlined" fullWidth className="two-line-input">
											<InputLabel htmlFor="input-with-icon-adornment" className="custom-label optional-label">
											  GENDER
											</InputLabel>
											<OutlinedInput
												defaultValue="- "
												id="gender-input"
												size="medium"
											/>
										</FormControl>
									</Grid>
								</Grid>
							</div>
							
							<div className="form-row">
								<Grid container spacing={2}>
									<Grid item xs={9}>
										<FormControl variant="outlined" fullWidth className="two-line-input">
											<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
											  EMAIL
											</InputLabel>
											<OutlinedInput
												defaultValue="- "
												id="email-input"
												size="medium"
											/>
										</FormControl>
									</Grid>
									
									<Grid item xs={3}>
										<FormControl variant="outlined" fullWidth className="two-line-input">
											<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
											  MOBILE
											</InputLabel>
											<OutlinedInput
												defaultValue="- "
												id="mobile-input"
												size="medium"
											/>
										</FormControl>
									</Grid>
								</Grid>
							</div>
							
							<div className="form-row">
								<Grid container spacing={2}>
									<Grid item xs={6}>
										<FormControl variant="outlined" fullWidth className="two-line-input">
											<InputLabel htmlFor="input-with-icon-adornment" className="custom-label optional-label">
											  BUSINESS
											</InputLabel>
											<OutlinedInput
												defaultValue="- "
												id="business-input"
												size="medium"
											/>
										</FormControl>
									</Grid>
									
									<Grid item xs={3}>
										<FormControl variant="outlined" fullWidth className="two-line-input">
											<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
											  PASSWORD
											</InputLabel>
											<OutlinedInput
												defaultValue="- "
												id="password-input"
												size="medium"
											/>
										</FormControl>
									</Grid>
									
									<Grid item xs={3}>
										<FormControl variant="outlined" fullWidth className="two-line-input">
											<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
											  CONFIRM PASSWORD
											</InputLabel>
											<OutlinedInput
												defaultValue="- "
												id="lconfirm-password-input"
												size="medium"
											/>
										</FormControl>
									</Grid>
								</Grid>
							</div>
							
							<div className="form-row terms-conditions-wrapper">
								<Grid item xs={12} className="terms-checkbox-wrapper">
									<FormGroup>
									  <FormControlLabel control={<Checkbox />} label="" />
									</FormGroup>
									<p className="terms-conditions-text">I accept the <a href="/privacy-policy/" className="terms-link">Privacy policy</a> &amp; the <a href="/terms-of-use/" className="terms-link">Terms of Use</a></p>
								</Grid>
							</div>
						</div>

					</Grid>

				</Grid>
				
				<Grid container spacing={2} className="account-button-wrapper">
					<Grid item xs={12}>
						<FormControl className="accountButtonControl">
							<Button 
								className="createAccountButton" 
								size="small" 
								variant="contained">
								Create my Account
							</Button>
						</FormControl>
					</Grid>
				</Grid>
				
			</Container>
			
		</>
	);
}