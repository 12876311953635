import React from 'react';
import './App.css';
import { createTheme } from '@mui/material/styles'
import { ThemeProvider } from '@mui/material/styles';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import One from "./pages/One";
import Two from "./pages/Two";
import Three from "./pages/Three";
import Four from "./pages/Four";
import Five from "./pages/Five";
import Six from "./pages/Six";
import Seven from "./pages/Seven";
import Eight from "./pages/Eight";
import Nine from "./pages/Nine";
import Ten from "./pages/Ten";
import Eleven from "./pages/Eleven";
import Twelve from "./pages/Twelve";
import Thirteen from "./pages/Thirteen";
import Fourteen from "./pages/Fourteen";
import Fifteen from "./pages/Fifteen";
import Sixteen from "./pages/Sixteen";
import Seventeen from "./pages/Seventeen";
import Eighteen from "./pages/Eighteen";
import Nineteen from "./pages/Nineteen";
import Twenty from "./pages/Twenty";
import Twentyone from "./pages/Twentyone";
import Twentytwo from "./pages/Twentytwo";
import Twentythree from "./pages/Twentythree";
import Twentyfour from "./pages/Twentyfour";
import Twentyfive from "./pages/Twentyfive";
import Twentysix from "./pages/Twentysix";
import Home from "./pages/Home";


const theme = createTheme({
  palette: {
    primary: {
      light: '#999999',
      main: '#128DC3',
      dark: '#1C92AD',
    },
    secondary: {
		  main: '#FD5A5F',
    },
    background: {
	  default: '#ffffff',
	},
    action: {
      disabledBackground: '#f8d7d8',
      disabled: 'white'
    }
  },
  shape: {
    borderRadius: 6,
  },
  components: {
    MuiContainer: {
        styleOverrides: {
            root: {
                maxWidth: '1568px'
            },
            maxWidthMd: {
                maxWidth: 320,
            },
            maxWidthLg: {
                maxWidth: '1568px!important',
            },
        },
    },
    MuiButton: {
      styleOverrides: {
        root: {
        },
      },
    },
  },
});

function App() {
  return (
<ThemeProvider theme={theme}>
      <Router>
        <div>
          
          {/* A <Routes> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Routes>
            <Route path="/twentysix" element={<Twentysix />} />
            <Route path="/twentyfive" element={<Twentyfive />} />
            <Route path="/twentyfour" element={<Twentyfour />} />
            <Route path="/twentythree" element={<Twentythree />} />
            <Route path="/twentytwo" element={<Twentytwo />} />
            <Route path="/twentyone" element={<Twentyone />} />
            <Route path="/twenty" element={<Twenty />} />
            <Route path="/nineteen" element={<Nineteen />} />
            <Route path="/eighteen" element={<Eighteen />} />
            <Route path="/seventeen" element={<Seventeen />} />
            <Route path="/sixteen" element={<Sixteen />} />
            <Route path="/fifteen" element={<Fifteen />} />
            <Route path="/fourteen" element={<Fourteen />} />
            <Route path="/thirteen" element={<Thirteen />} />
            <Route path="/twelve" element={<Twelve />} />
            <Route path="/eleven" element={<Eleven />} />
            <Route path="/ten" element={<Ten />} />
            <Route path="/nine" element={<Nine />} />
            <Route path="/eight" element={<Eight />} />
            <Route path="/seven" element={<Seven />} />
            <Route path="/six" element={<Six />} />
            <Route path="/five" element={<Five />} />
            <Route path="/four" element={<Four />} />
            <Route path="/three" element={<Three />} />
            <Route path="/two" element={<Two />} />
            <Route path="/one" element={<One />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </div>
      </Router>
      </ThemeProvider>
  );
}

export default App;