import CardArrowIcon from '../assets/svg/my-acc-card-arrow.svg';
import { styled } from '@mui/material/styles';
  
export default function NueCard( props: any ) {
	let title = props.title;
	let subtitle = props.subtitle;
	let icon = props.icon;
	let link = props.link;
	
	return (
		<div className="myacccard cardbox">
			<div className="myacccard-icon">
				<div className="card-lrg-icon"><img src={icon} /></div>
			</div>
			<div className="myacccard-header">
				<h2 className="myacccard-title">{title}</h2>
				<p className="myacccard-subtitle">{subtitle}</p>
			</div>
			<div className="myacccard-link">
				<img className="card-arrow-icon" src={CardArrowIcon} />
			</div>
		</div>
	);
}