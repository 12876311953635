import NuecardImage from '../assets/images/nuecard-image.png';
import DistanceWalkIcon from '../assets/svg/icon-walking.svg';
import PriceFromIcon from '../assets/svg/icon-price.svg';
import ClearanceHeightIcon from '../assets/svg/icon-height.svg';
import TariffsIcon from '../assets/svg/icon-tariffs.svg';
import TradinghoursIcon from '../assets/svg/icon-tradinghrs.svg';
import AmenitiesIcon from '../assets/svg/icon-amenities.svg';
import PinIcon from '../assets/svg/icon-pin-2.svg';
import { styled } from '@mui/material/styles';
import DeleteIcon from '../assets/svg/delete-icon.svg';
  
export default function NueCard( props: any ) {
	let title = props.title;
	let parkid = props.parkid;
	let address = props.address;
	let subtitle = props.subtitle;
	let Image = props.image;
	let km = props.km;
	let price = props.price;
	let carsize = props.carsize;
	let tariffs = props.tariffs;
	let hours = props.hours;
	let amenities = props.amenities;
	
	return (
		<div className="nuecard cardbox my-account">
			<div className="nuecard-header">
				<div className="nuecard-delete">
					<a href=""><img className="delete-park-icon" src={DeleteIcon} /></a>
				</div>
				<div className="nuecard-title">
					{title} <span className="nuecard-id">(id. {parkid})</span>
				</div>
				<div className="nuecard-address">
					{address}
				</div>
				<div className="nuecard-subtitle">
					{subtitle}
				</div>
			</div>
			<div className="nuecard-image">
				<div className="open-map"><a href="#"><img className="open-map-icon" src={PinIcon} /></a></div>
				<img src={Image} />
			</div>
			<div className="nuecard-details">
				<div className="nuecard-details-item km">
					<img className="walk-icon" src={DistanceWalkIcon} />{km}
				</div>
				<div className="nuecard-details-item price">
					<img className="price-icon" src={PriceFromIcon} />From: {price}
				</div>
				<div className="nuecard-details-item carsize">
					<img className="height-icon" src={ClearanceHeightIcon} />{carsize}
				</div>
			</div>
			<div className="nuecard-info">
				<div className="nuecard-info-item">
					<img className="tariffs-icon" src={TariffsIcon} />Tariffs
				</div>
				<div className="nuecard-info-item">
					<img className="trading-hrs-icon" src={TradinghoursIcon} />Trading Hrs
				</div>
				<div className="nuecard-info-item">
					<img className="amenities-icon" src={AmenitiesIcon} />Amenities
				</div>
			</div>
		</div>
	);
}