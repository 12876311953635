import Grid from '@mui/material/Grid';
import SelectionIcon from '../assets/svg/icon-basket.svg';
import EditIcon from '../assets/svg/icon-edit-2.svg';
  
export default function Filters() {
	return <div className="selection cardbox">
		<Grid container spacing={1} className="selectionbox">
			<Grid item md={12} className="selection-title-row">
				<span className="selectionTitle"><img className="HeaderSelectionIcon" src={SelectionIcon} alt="Your Selection" />Your Selection</span>
			</Grid>
			<Grid item xs={12} md={8}>
				<p className="selection-line-item-title">NuevoRevQR - M</p>
			</Grid>
			<Grid item xs={12} md={3}>
				<p className="selection-line-item-price">$14.00</p>
			</Grid>
			<Grid item xs={12} md={1}>
				<img className="EditSelectionIcon" src={EditIcon} alt="Edit Selection" />
			</Grid>
			<Grid item xs={12} md={8}>
				<p className="selection-line-item-title"><strong>Sub Total</strong> (inc GST):</p>
			</Grid>
			<Grid item xs={12} md={3}>
				<p className="selection-line-item-price"><strong>$14.00</strong></p>
			</Grid>

			<Grid item xs={12} md={8}>
				<p className="selection-line-item-title">Airport Levy ($10.00)</p>
			</Grid>
			<Grid item xs={12} md={3}>
				<p className="selection-line-item-price">$10.00</p>
			</Grid>
			<Grid item xs={12} md={8}>
				<p className="selection-line-item-title">Carbon Levy (5%)</p>
			</Grid>
			<Grid item xs={12} md={3}>
				<p className="selection-line-item-price">$0.63</p>
			</Grid>
			<Grid item xs={12} md={1}>
			</Grid>
			<Grid item xs={12} md={8}>
				<p className="selection-line-item-title"><strong>Total</strong></p>
			</Grid>
			<Grid item xs={12} md={3}>
				<p className="selection-line-item-price"><strong>$24.63</strong></p>
			</Grid>
		</Grid>
	</div>
}