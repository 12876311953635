import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import NuevotekLogo from '../assets/svg/nuevopark-logo.svg';
import ParkIcon from '../assets/svg/icon-parking.svg';
import PinIcon from '../assets/svg/icon-pin-2.svg';
import CalendarIcon from '../assets/svg/icon-calendar-solid.svg';
import OfferIcon from '../assets/svg/icon-offer.svg';
import TickIcon from '../assets/svg/icon-step-tick.svg';

export default function Header() {
	return <>
		<div className="headerBackground"></div>
		<Container className="headerWrapper">
			<div className="topHeader">
				<div className="headerTitle">
					<h2>Find the best parking deals online<br/>
					& reserve your space for peace of mind.</h2>
				</div>
				<div className="headerNavigationWrapper">
					<img className="headerLogo" src={NuevotekLogo} alt="NuevoPark" />
					<div className="headerNavigation">
						<a href="/one/" className="navLink">Home</a>
						<a href="/nine/" className="navLink">Manage my Booking</a>
						<a href="/twelve/" className="navLink">Create an Account</a>
						<a href="/eleven/" className="navLink">Sign In</a>
					</div>
				</div>
			</div>
			
			<div className="bookingControls">
				<div className="nuevo-stepper">
					<div className="stepper-text">
						<div className="text-wrapper step-one">
							<span className="step-text active-step">DESTINATION &amp; DATES</span>
						</div>
						<div className="text-wrapper step-two">
							<span className="step-text inactive-step">SELECT PARKING OFFER</span>
						</div>
						<div className="text-wrapper step-three">
							<span className="step-text inactive-step">SELECT ADD ONS</span>
						</div>
						<div className="text-wrapper step-four">
							<span className="step-text inactive-step">SELECT REMINDERS</span>
						</div>
						<div className="text-wrapper step-five">
							<span className="step-text inactive-step">PAYMENT</span>
						</div>
					</div>
					<div className="stepper-icons">
						<div className="stepper-progress-wrapper zero-one">
							<div className="progress-bar active"></div>
						</div>
						<div className="stepper-icon-wrapper step-one">
							<span className="step-circle active"><img className="StepCompleteIcon" src={TickIcon} alt="Step Complete" /></span>
						</div>
						<div className="stepper-progress-wrapper one-two">
							<div className="progress-bar active"></div>
						</div>
						<div className="stepper-icon-wrapper step-two">
							<span className="step-circle active"><img className="StepCompleteIcon" src={TickIcon} alt="Step Complete" /></span>
						</div>
						<div className="stepper-progress-wrapper two-three">
							<div className="progress-bar active"></div>
						</div>
						<div className="stepper-icon-wrapper step-three">
							<span className="step-circle active"><img className="StepCompleteIcon" src={TickIcon} alt="Step Complete" /></span>
						</div>
						<div className="stepper-progress-wrapper three-four">
							<div className="progress-bar active"></div>
						</div>
						<div className="stepper-icon-wrapper step-four">
							<span className="step-circle active"><img className="StepCompleteIcon" src={TickIcon} alt="Step Complete" /></span>
						</div>
						<div className="stepper-progress-wrapper four-five">
							<div className="progress-bar active"></div>
						</div>
						<div className="stepper-icon-wrapper step-five">
							<span className="step-circle active">5</span>
						</div>
						<div className="stepper-progress-wrapper five-six">
							<div className="progress-bar inactive"></div>
						</div>
					</div>
				</div>
				
				<div className="bookingButtons selected-carpark">
					<Grid container spacing={2}>
						<Grid item xs={4} className="selected-park-column">
							<img className="SelectedParkIcon" src={ParkIcon} alt="Car Park Icon" /><span className="selected-carpark-name">CarePark Franklin</span><img className="SelectedParkPinIcon" src={PinIcon} alt="Pin Icon" />
						</Grid>
						<Grid item xs={4} className="selected-time-column">
							<img className="SelectedParkCalendarIcon" src={CalendarIcon} alt="Calendar Icon" /><span className="selected-carpark-date-time">16th Nov 22 <span className="selected-time">9:00</span> <span className="selected-hyphen">-</span> 20th Nov 22 <span className="selected-time">14:00</span></span>
						</Grid>
						<Grid item xs={4} className="selected-offer-column">
							<img className="SelectedParkOfferIcon" src={OfferIcon} alt="COffer Icon" /><span className="selected-carpark-offer">Day Parker - Standard</span>
						</Grid>
					</Grid>
				</div>
			</div>
		</Container>
	</>
}