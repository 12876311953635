import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Header from '../components/header-step-one'
import Filters from '../components/filters';
import NueCard from '../components/nuecard';
import CarParkFranklin from '../assets/images/nuevotek-car-park-tile-01.jpg';
import CarParkGrote from '../assets/images/nuevotek-car-park-tile-02.jpg';
import CarParkPitt from '../assets/images/nuevotek-car-park-tile-03.jpg';
import ListIcon from '../assets/svg/icon-list.svg';
import MapIcon from '../assets/svg/icon-map.svg';

export default function CenteredGrid() {
	return (
		<>
			<Header />
			<div className="icon-wrapper">
				<svg className="list-icon" id="list" xmlns="http://www.w3.org/2000/svg" width="22.53" height="18.3" viewBox="0 0 22.53 18.3"><path d="m21.82,7.74H7.74c-.39,0-.7.32-.7.7v1.41c0,.39.32.7.7.7h14.08c.39,0,.7-.32.7-.7v-1.41c0-.39-.32-.7-.7-.7Zm0-7.04H7.74c-.39,0-.7.32-.7.7v1.41c0,.39.32.7.7.7h14.08c.39,0,.7-.32.7-.7v-1.41c0-.39-.32-.7-.7-.7Zm0,14.08H7.74c-.39,0-.7.32-.7.7v1.41c0,.39.32.7.7.7h14.08c.39,0,.7-.32.7-.7v-1.41c0-.39-.32-.7-.7-.7ZM3.52,7.04H.7C.32,7.04,0,7.35,0,7.74v2.82C0,10.95.32,11.26.7,11.26h2.82c.39,0,.7-.32.7-.7v-2.82c0-.39-.32-.7-.7-.7ZM3.52,0H.7C.32,0,0,.32,0,.7v2.82C0,3.91.32,4.22.7,4.22h2.82c.39,0,.7-.32.7-.7V.7c0-.39-.32-.7-.7-.7Zm0,14.08H.7C.32,14.08,0,14.39,0,14.78v2.82C0,17.99.32,18.3.7,18.3h2.82c.39,0,.7-.32.7-.7v-2.82c0-.39-.32-.7-.7-.7Z" fill="#009eff"/></svg>
				<svg className="map-icon" id="map" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.5 20.6"><path d="M22.1,0.1C21.9,0,21.8,0,21.6,0c-0.2,0-0.3,0-0.5,0.1L15,3.6L7.9,0.1c0,0,0,0,0,0C7.8,0,7.6,0,7.5,0
				C7.4,0,7.3,0,7.1,0.1c0,0-0.1,0-0.1,0L0.5,3.9C0.2,4,0,4.3,0,4.7v15c0,0.3,0.2,0.6,0.5,0.8c0.3,0.2,0.6,0.2,0.9,0L7.5,17l7.1,3.5
				c0,0,0,0,0,0c0,0,0,0,0,0c0.2,0.1,0.5,0.1,0.7,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0l6.6-3.8c0.3-0.1,0.5-0.5,0.5-0.8v-15
				C22.6,0.6,22.4,0.3,22.1,0.1z M8.4,2.4l5.7,2.8v13l-5.7-2.8V2.4z M1.9,5.2l4.7-2.7v13l-4.7,2.6V5.2z M20.8,15.5L16,18.2V5.4l4.8-2.8
				V15.5z" fill="#383838"/></svg>
			</div>
			
			<Container 
				className="bodyContainer"
				maxWidth={false}>
				<Grid container spacing={2}>
					<Grid item xs={3}>
						<Filters />
					</Grid>
					
					<Grid item xs={9}>
						<div className="park-wrapper favourite">
							<NueCard 
								title="Nuevotek Parking"
								parkid="NP"
								address="50 Grenfell Street, Adelaide SA 5000"
								subtitle="Conveniently located in the heart of the Adelaide Central Business District."
								image={CarParkFranklin}
								km="0.5 km"
								price="$12 per day"
								carsize="2.75m"
								tariffs=""
								hours=""
								amenities=""
							/>
						</div>
						
						<div className="park-wrapper">
							<NueCard 
								title="Nuevotek Parking"
								parkid="NP"
								address="50 Grenfell Street, Adelaide SA 5000"
								subtitle="Conveniently located in the heart of the Adelaide Central Business District."
								image={CarParkFranklin}
								km="0.5 km"
								price="$12 per day"
								carsize="2.75m"
								tariffs=""
								hours=""
								amenities=""
							/>
						</div>
					</Grid>
				</Grid>
			</Container>
		</>
	);
}