import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Header from "../components/header-my-account";
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Button from '@mui/material/Button';
import HeaderIcon from '../assets/svg/my-activity-heading-icon.svg';
import ProfileMenuIcon from '../assets/svg/my-acc-profile-icon-sml.svg';
import VehiclesMenuIcon from '../assets/svg/my-acc-vehicles-icon-sml.svg';
import CardsMenuIcon from '../assets/svg/my-acc-cards-icon-sml.svg';
import CarParksMenuIcon from '../assets/svg/my-acc-parks-icon-sml-2.svg';
import SubsMenuIcon from '../assets/svg/my-acc-subs-icon-sml.svg';
import SettingsMenuIcon from '../assets/svg/my-acc-settings-icon-sml.svg';
import SecurityMenuIcon from '../assets/svg/my-acc-security-icon-sml.svg';
import ForgetMeMenuIcon from '../assets/svg/my-acc-forget-icon-sml.svg';
import VisaLogo from '../assets/svg/ccard-icon-visa.svg';
import MCardLogo from '../assets/svg/ccard-icon-mcard.svg';
import EditIcon from '../assets/svg/edit-icon.svg';
import DeleteIcon from '../assets/svg/delete-icon.svg';
import AddItem from '../assets/svg/add-item-icon.svg';

export default function CenteredGrid() {
	return (
		<>
			<Header />
				<div className="icon-wrapper">
					
				</div>
			<Container 
				className="bodyContainer no-header"
				maxWidth={false}>
				<Grid container spacing={2}>
					
					<Grid item xs={12}>
						<div className="my-account-wrapper">
							<Grid container spacing={2} className="flex-height-wrapper">
								<Grid item xs={3}>
									<h2 className="my-account-header"><img className="my-account-header-icon" src={HeaderIcon} alt="List" />My Account</h2>
									<ul className="my-account-menu">
										<li className="profile"><img className="profile-menu-icon" src={ProfileMenuIcon} alt="Profile" /><a href="">Profile</a></li>
										<li className="vehicles"><img className="vehicles-menu-icon" src={VehiclesMenuIcon} alt="Vehicles" /><a href="">Vehicles</a></li>
										<li className="cards"><img className="cards-menu-icon" src={CardsMenuIcon} alt="Cards" /><a href="">Cards</a></li>
										<li className="car-parks"><img className="car-parks-menu-icon" src={CarParksMenuIcon} alt="Car Parks" /><a href="">Car Parks</a></li>
										<li className="subscriptions"><img className="subscriptions-menu-icon" src={SubsMenuIcon} alt="Subscriptions" /><a href="">Subscriptions</a></li>
										<li className="settings"><img className="settings-menu-icon" src={SettingsMenuIcon} alt="Settings" /><a href="">Settings</a></li>
										<li className="security"><img className="security-menu-icon" src={SecurityMenuIcon} alt="Security" /><a className="current" href="">Security</a></li>
										<li className="forget-me"><img className="forget-me-menu-icon" src={ForgetMeMenuIcon} alt="Forget Me" /><a href="">Forget Me</a></li>
									</ul>
								</Grid>
								
								<Grid item xs={9} className="bordered-column">
									<div className="flex-button-wrapper">
										<div className="sub-header-wrapper">
											<h3 className="my-account-sub-header">Security</h3>
										</div>
										<div className="form-row">
											<Grid container spacing={2}>
												<Grid item xs={6}>
													<FormControl variant="outlined" fullWidth className="two-line-input">
														<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
													  	OLD PASSWORD
														</InputLabel>
														<OutlinedInput
															defaultValue="- "
															id="old-password-input"
															size="medium"
														/>
													</FormControl>
												</Grid>
												
												<Grid item xs={6}>
													<FormControl variant="outlined" fullWidth className="two-line-input">
														<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
													  	MOBILE
														</InputLabel>
														<OutlinedInput
															defaultValue="041* *** 723"
															id="modile-input"
															size="medium"
														/>
													</FormControl>
												</Grid>
												
											</Grid>
										</div>
										
										<div className="form-row">
											<Grid container spacing={2}>
												<Grid item xs={6}>
													<FormControl variant="outlined" fullWidth className="two-line-input">
														<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
													  	NEW PASSWORD
														</InputLabel>
														<OutlinedInput
															defaultValue="- "
															id="new-password-input"
															size="medium"
														/>
													</FormControl>
												</Grid>
												
												<Grid item xs={6}>
													<FormControl variant="outlined" fullWidth className="two-line-input">
														<InputLabel htmlFor="input-with-icon-adornment" className="custom-label">
													  	CONFIRM PASSWORD
														</InputLabel>
														<OutlinedInput
															defaultValue="- "
															id="confirm-password-input"
															size="medium"
														/>
													</FormControl>
												</Grid>
												
											</Grid>
										</div>
										
										<div className="bottom-button-wrapper">
											<FormControl className="greyButtonControl">
												<Button 
													className="greyButton" 
													size="small" 
													variant="contained">
													Next
												</Button>
											</FormControl>
										</div>
									</div>
								</Grid>
							</Grid>
						</div>

					</Grid>

				</Grid>
				
			</Container>
			
		</>
	);
}