import NuecardImage from '../assets/images/nuecard-image.png';
import Button from '@mui/material/Button';
import DistanceWalkIcon from '../assets/svg/icon-walking.svg';
import PriceFromIcon from '../assets/svg/icon-price.svg';
import ClearanceHeightIcon from '../assets/svg/icon-height.svg';
import TariffsIcon from '../assets/svg/icon-tariffs.svg';
import TradinghoursIcon from '../assets/svg/icon-tradinghrs.svg';
import AmenitiesIcon from '../assets/svg/icon-amenities.svg';
import PinIcon from '../assets/svg/icon-pin-2.svg';
import { styled } from '@mui/material/styles';

const BlueButton = styled( Button )({
  color: '#ffffff;',
  borderColor: '#009eff',
  backgroundColor: '#009eff',
  width: '100%',
  textTransform: 'none',
  fontSize: '1.1rem',
  letterSpacing: '0',
  borderRadius: '3em',
  padding: '5px 15px',
  });
  
export default function NueCard( props: any ) {
	let title = props.title;
	let parkid = props.parkid;
	let address = props.address;
	let subtitle = props.subtitle;
	let Image = props.image;
	let km = props.km;
	let price = props.price;
	let carsize = props.carsize;
	let tariffs = props.tariffs;
	let hours = props.hours;
	let amenities = props.amenities;
	
	return (
		<div className="selected-park">
			<div className="selected-park-header">
				<div className="selected-park-title">
					{title} <span className="selected-park-id">(ID. {parkid})</span>
				</div>
				<div className="selected-park-address">
					{address}
				</div>
				<div className="selected-park-subtitle">
					{subtitle}
				</div>
			</div>
			<div className="selected-park-image">
				<img src={Image} />
			</div>
			<div className="selected-park-details">
				<div className="selected-park-details-item km">
					<img className="walk-icon" src={DistanceWalkIcon} />{km}
				</div>
				<div className="selected-park-details-item price">
					<img className="price-icon" src={PriceFromIcon} />From: {price}
				</div>
				<div className="selected-park-details-item carsize">
					<img className="height-icon" src={ClearanceHeightIcon} />{carsize}
				</div>
			</div>
			<div className="selected-park-info">
				<div className="selected-park-info-item">
					<img className="tariffs-icon" src={TariffsIcon} />Tariffs
				</div>
				<div className="selected-park-info-item">
					<img className="trading-hrs-icon" src={TradinghoursIcon} />Trading Hrs
				</div>
				<div className="selected-park-info-item">
					<img className="amenities-icon" src={AmenitiesIcon} />Amenities
				</div>
			</div>
			<div className="selected-park-button">
				<BlueButton 
					variant="outlined">
					Search Nearby Carparks
				</BlueButton>
			</div>
		</div>
	);
}