import Box from '@mui/material/Box';
import { NUESlider } from './nueslider';
import Grid from '@mui/material/Grid';
import FilterIcon from '../assets/svg/icon-filters.svg';
import Button from '@mui/material/Button';
import ButtonIconDisabled from '../assets/svg/icon-disabled.svg'
import ButtonIconCovered from '../assets/svg/icon-covered.svg'
import ButtonIconCctv from '../assets/svg/icon-cctv.svg'
import ButtonIconEvcharge from '../assets/svg/icon-evcharge.svg'
import ButtonIconGated from '../assets/svg/icon-gated.svg'
import ButtonIconMoto from '../assets/svg/icon-motorcycle.svg'
import ButtonIconCarWash from '../assets/svg/icon-carwash.svg'
import ButtonIconLift from '../assets/svg/icon-lift.svg'
import ButtonIconManned from '../assets/svg/icon-manned.svg'
import ButtonIconValet from '../assets/svg/icon-valet.svg'
import IconRefresh from '../assets/svg/icon-refresh.svg'
import { styled } from '@mui/material/styles';

const FilterButton = styled( Button )({
  height: '100%'
});

const BlueButton = styled( Button )({
  color: '#ffffff;',
  borderColor: '#009eff',
  backgroundColor: '#009eff',
  width: '100%',
  textTransform: 'none',
  fontSize: '1.1rem',
  letterSpacing: '0',
  borderRadius: '3em',
  padding: '5px 15px',
  });
  
export default function Filters() {
	return <div className="filters cardbox">
		<Grid container spacing={1} className="filterbox">
			<Grid item md={12} className="filterheader">
				<span className="filterTitle"><img className="HeaderFilterIcon" src={FilterIcon} />Filters</span>
				<a href="#" className="refresh-icon"><img className="HeaderRefreshIcon" src={IconRefresh} /></a>
			</Grid>
			
			<Grid item xs={12} md={3}>
				<label className="filterLabel">Budget</label>
			</Grid>
			
			<Grid item xs={12} md={9} className="filterSlider">
				<NUESlider
					aria-label="Budget"
					defaultValue={0}
					min={0}
					max={200}
					valueLabelDisplay="on"
					valueLabelFormat={(value) => {
						return ( '$' + value );
					}} 
				/>
			</Grid>
			
			<Grid item xs={12} md={3}>
				<label className="filterLabel">Clearance</label>
			</Grid>
			
			<Grid item xs={12} md={9} className="filterSlider">
				<NUESlider
					aria-label="Clearance"
					defaultValue={1.8}
					min={0}
					max={5}
					valueLabelDisplay="on"
					valueLabelFormat={(value) => {
						return ( value + 'm' );
					}} 
				/>
			</Grid>
				
			<Grid item xs={12} md={3}>
				<label className="filterLabel">Distance</label>
			</Grid>
			
			<Grid item xs={12} md={9} className="filterSlider">
				<NUESlider
					aria-label="Distance"
					defaultValue={3}
					min={0}
					max={20}
					valueLabelDisplay="on"
					valueLabelFormat={(value) => {
						return ( value + 'km radius' );
					}} 
				/>
			</Grid>
			<div className="spacer"></div>
			<Grid item xs={12} md={6}>
				<FilterButton 
					variant="outlined" 
					size="small" 
					className="filterButtons" 
					style={{justifyContent: "flex-start"}} 
					startIcon={<img className="button-icon-cctv" src={ButtonIconCctv} />} >
						CCTV
				</FilterButton>
			</Grid>
			<Grid item xs={12} md={6}>
				<FilterButton 
					variant="outlined" 
					size="small" 
					className="filterButtons" 
					style={{justifyContent: "flex-start"}} 
					startIcon={<img className="button-icon-carwash" src={ButtonIconCarWash} />} >
						Car Wash
				</FilterButton>
			</Grid>
			
			<Grid item xs={12} md={6}>
				<FilterButton 
					variant="outlined" 
					size="small" 
					className="filterButtons" 
					style={{justifyContent: "flex-start"}} 
					startIcon={<img className="button-icon-disabled" src={ButtonIconDisabled} />} >
						Disabled Space
				</FilterButton>
			</Grid>
			<Grid item xs={12} md={6}>
				<FilterButton 
					variant="outlined" 
					size="small" 
					className="filterButtons" 
					style={{justifyContent: "flex-start"}} 
					startIcon={<img className="button-icon-evcharge" src={ButtonIconEvcharge} />} >
						EV Charger
				</FilterButton>
			</Grid>
			
			<Grid item xs={12} md={6}>
				<FilterButton 
					variant="outlined" 
					size="small" 
					className="filterButtons" 
					style={{justifyContent: "flex-start"}} 
					startIcon={<img className="button-icon-lift" src={ButtonIconLift} />} >
						Lift
				</FilterButton>
			</Grid>
			<Grid item xs={12} md={6}>
				<FilterButton 
					variant="outlined" 
					size="small" 
					className="filterButtons" 
					style={{justifyContent: "flex-start"}} 
					startIcon={<img className="button-icon-manned" src={ButtonIconManned} />} >
						Manned
				</FilterButton>
			</Grid>
			
			<Grid item xs={12} md={6}>
				<FilterButton 
					variant="outlined" 
					size="small" 
					className="filterButtons" 
					style={{justifyContent: "flex-start"}} 
					startIcon={<img className="button-icon-moto" src={ButtonIconMoto} />} >
						Motor Bike
				</FilterButton>
			</Grid>
			<Grid item xs={12} md={6}>
				<FilterButton 
					variant="outlined" 
					size="small" 
					className="filterButtons" 
					style={{justifyContent: "flex-start"}} 
					startIcon={<img className="button-icon-valet" src={ButtonIconValet} />} >
						Valet
				</FilterButton>
			</Grid>
		</Grid>
		
		<Grid container spacing={1} className="filterfooter">
			<Grid item md={12} className="anchored-button">
				<BlueButton 
					variant="outlined">
					Update Search
				</BlueButton>
			</Grid>
		</Grid>
	</div>
}