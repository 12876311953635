import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Header from "../components/header-my-account";
import HeaderIcon from '../assets/svg/my-activity-heading-icon.svg';
import ProfileMenuIcon from '../assets/svg/my-acc-profile-icon-sml.svg';
import VehiclesMenuIcon from '../assets/svg/my-acc-vehicles-icon-sml.svg';
import CardsMenuIcon from '../assets/svg/my-acc-cards-icon-sml.svg';
import CarParksMenuIcon from '../assets/svg/my-acc-parks-icon-sml-2.svg';
import SubsMenuIcon from '../assets/svg/my-acc-subs-icon-sml.svg';
import SettingsMenuIcon from '../assets/svg/my-acc-settings-icon-sml.svg';
import SecurityMenuIcon from '../assets/svg/my-acc-security-icon-sml.svg';
import ForgetMeMenuIcon from '../assets/svg/my-acc-forget-icon-sml.svg';
import VisaLogo from '../assets/svg/ccard-icon-visa.svg';
import MCardLogo from '../assets/svg/ccard-icon-mcard.svg';
import EditIcon from '../assets/svg/edit-icon.svg';
import DeleteIcon from '../assets/svg/delete-icon.svg';
import AddItem from '../assets/svg/add-item-icon.svg';
import NueCardMyAccount from '../components/nuecard-my-account';
import CarParkFranklin from '../assets/images/nuevotek-car-park-tile-01.jpg';
import CarParkGrote from '../assets/images/nuevotek-car-park-tile-02.jpg';
import CarParkPitt from '../assets/images/nuevotek-car-park-tile-03.jpg';
import ListIcon from '../assets/svg/icon-list.svg';
import MapIcon from '../assets/svg/icon-map.svg';

export default function CenteredGrid() {
	return (
		<>
			<Header />
				<div className="icon-wrapper">
					
				</div>
			<Container 
				className="bodyContainer no-header"
				maxWidth={false}>
				<Grid container spacing={2}>
					
					<Grid item xs={12}>
						<div className="my-account-wrapper">
							<Grid container spacing={2} className="flex-height-wrapper">
								<Grid item xs={3}>
									<h2 className="my-account-header"><img className="my-account-header-icon" src={HeaderIcon} alt="List" />My Account</h2>
									<ul className="my-account-menu">
										<li className="profile"><img className="profile-menu-icon" src={ProfileMenuIcon} alt="Profile" /><a href="">Profile</a></li>
										<li className="vehicles"><img className="vehicles-menu-icon" src={VehiclesMenuIcon} alt="Vehicles" /><a href="">Vehicles</a></li>
										<li className="cards"><img className="cards-menu-icon" src={CardsMenuIcon} alt="Cards" /><a href="">Cards</a></li>
										<li className="car-parks"><img className="car-parks-menu-icon" src={CarParksMenuIcon} alt="Car Parks" /><a className="current" href="">Car Parks</a></li>
										<li className="subscriptions"><img className="subscriptions-menu-icon" src={SubsMenuIcon} alt="Subscriptions" /><a href="">Subscriptions</a></li>
										<li className="settings"><img className="settings-menu-icon" src={SettingsMenuIcon} alt="Settings" /><a href="">Settings</a></li>
										<li className="security"><img className="security-menu-icon" src={SecurityMenuIcon} alt="Security" /><a href="">Security</a></li>
										<li className="forget-me"><img className="forget-me-menu-icon" src={ForgetMeMenuIcon} alt="Forget Me" /><a href="">Forget Me</a></li>
									</ul>
								</Grid>
								
								<Grid item xs={9} className="bordered-column">
									<div className="sub-header-wrapper">
										<h3 className="my-account-sub-header">Car Parks</h3>
									</div>
									<Grid container spacing={2}>
										<Grid item xs={4}>
											<NueCardMyAccount 
												title="Carpark Franklin"
												parkid="47235"
												address="69 Franklin Street, Adelaide SA 5000"
												subtitle="Convenient parking near the Central Market"
												image={CarParkFranklin}
												km="0.35km"
												price="$12 per day"
												carsize="2.1m"
												tariffs=""
												hours=""
												amenities=""
											/>
										</Grid>
										
										<Grid item xs={4}>
											<NueCardMyAccount
												title="CarePark 80 Grote"
												parkid="47310"
												address="80 Grote Street, Adelaide SA 5000"
												subtitle="Convenient parking for CBD"
												image={CarParkGrote}
												km="0.42km"
												price="$8 per day"
												carsize="4.0m"
												tariffs=""
												hours=""
												amenities=""
											/>
										</Grid>
										
										<Grid item xs={4}>
											<NueCardMyAccount 
												title="CarePark Pitt St"
												parkid="47225"
												address="15 Pitt Street, Adelaide SA 5000"
												subtitle="Close to Her Majesty's Theatre"
												image={CarParkPitt}
												km="0.60km"
												price="$12 per day"
												carsize="2.4m"
												tariffs=""
												hours=""
												amenities=""
											/>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
						</div>

					</Grid>

				</Grid>
				
			</Container>
			
		</>
	);
}